import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button } from 'scala'
import { useRouter } from 'next/router'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { EmailPasswordSignIn, HeaderAuth, SocialSignIn } from '../../components'
import { Title } from '../../components/title'
import { useEvent } from '../../hooks/analytics'
import { useAuthentication } from '../../hooks/authentication'
import { FirebaseAuth } from '../../lib/firebase'
import { rudderstackIdentify } from '../../lib/rudderstack'
import styles from './login.module.scss'

export const Login = (): ReactElement => {
  const { i18n } = useLingui()
  const { query, push } = useRouter()
  const [eventFired, setEventFired] = useState(false)
  const { sendEvent } = useEvent()
  const [isSignIn, setIsSignIn] = useState(
    query && !Object.prototype.hasOwnProperty.call(query, 'signup')
  )

  const {
    error,
    loading,
    email,
    password,
    errorEmail,
    errorPassword,
    onChangeEmail,
    onChangePassword,
    signInApple,
    signInGoogle,
    signInTwitter,
    signInFacebook,
    signInEmail,
    signUpEmail,
    onBlur
  } = useAuthentication({
    isSignIn
  })

  const onGoResetPassword = useCallback(() => push('/reset-password'), [push])

  const toggleSignIn = useCallback(() => setIsSignIn(!isSignIn), [isSignIn])

  const onAuthenticated = useCallback(
    async (user: any) => {
      if (!user) return

      const { redirect } = query
      const { currentUser } = FirebaseAuth
      const { createdAt } = currentUser?.toJSON() as any
      const accountRecentlyCreated = Date.now() - createdAt < 5000
      const eventName = accountRecentlyCreated ? 'signed_up' : 'logged_in'

      if (user.uid && !eventFired) {
        setEventFired(true)
        rudderstackIdentify(user.uid)

        sendEvent({
          userId: user.uid,
          name: eventName,
          category: eventName === 'logged_in' ? 'engagement' : 'activation',
          customAttributes: {
            authentication: user?.providerData[0]?.providerId || 'not set'
          }
        })
      }

      push(redirect ? `/${redirect}` : '/library')
    },
    [push, eventFired, query, sendEvent]
  )

  useEffect(() => {
    const unsubscribe = FirebaseAuth.onAuthStateChanged(onAuthenticated)

    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [onAuthenticated])

  return (
    <div className={styles.page}>
      <HeaderAuth isSignIn={isSignIn} toggleSignIn={toggleSignIn} />

      <div className={styles.container}>
        {!isSignIn ? (
          <div className={styles.aside}>
            <div className={styles.artistImageContainer}>
              <img
                className={styles.artistImage}
                alt="Travis Dykes. Bassist, arranger, producer and teacher"
                src="https://storage.googleapis.com/scala-web/assets/images/artists/travis-dykes2.jpg"
              />
            </div>
            <p className={styles.artistDescription}>
              “If I had Moises when I was younger, it would have changed my
              playing completely.”
            </p>
            <div className={styles.artistQuote}>
              <p className={styles.artistName}>Travis Dykes</p>
              <p className={styles.artistLabel}>
                Bassist, arranger, producer and teacher
              </p>
            </div>
          </div>
        ) : null}

        <div className={styles.content}>
          <Title className={styles.title}>
            {isSignIn ? i18n._(t`actions.login`) : i18n._(t`actions.signup`)}
          </Title>

          <div className={styles.form}>
            <p className={styles.subtitle}>
              {isSignIn
                ? i18n._(t`auth.signin.label`)
                : i18n._(t`auth.signup.label`)}
            </p>

            <SocialSignIn
              isSignIn={isSignIn}
              className={styles.actions}
              signInApple={signInApple}
              signInGoogle={signInGoogle}
              signInTwitter={signInTwitter}
              signInFacebook={signInFacebook}
            />

            <p className={styles.subtitle}>
              {isSignIn
                ? i18n._(t`auth.signin.login`)
                : i18n._(t`auth.signup.email`)}
            </p>

            <EmailPasswordSignIn
              email={email}
              password={password}
              error={error}
              loading={loading}
              isSignIn={isSignIn}
              errorEmail={errorEmail}
              errorPassword={errorPassword}
              onChangeEmail={onChangeEmail}
              onChangePassword={onChangePassword}
              onBlur={onBlur}
              onSubmit={isSignIn ? signInEmail : signUpEmail}
            />

            {!isSignIn ? (
              <p className={styles.labelTerms}>
                <span // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: i18n
                      ._(t`auth.signup.agree`)
                      .replace(
                        /\*([^*]+)\*/,
                        `<a id="terms_link" target="_blank" href="/terms">$1</a>`
                      )
                      .replace(
                        /\*([^*]+)\*/,
                        `<a id="privacy_link" target="_blank" href="/privacy">$1</a>`
                      )
                  }}
                />
              </p>
            ) : (
              <div className={styles.containerResetPassword}>
                <Button
                  small
                  radius={1}
                  transparent
                  id="forgot_my_password_button"
                  title={i18n._(t`actions.password.forgot`)}
                  onClick={onGoResetPassword}
                  className={styles.buttonResetPassword}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
